import Img from 'gatsby-image';
import { FaArrowCircleUp } from 'react-icons/fa';
import React, { Component } from 'react';

import styled from 'styled-components';
const ImageHidder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

class Gallery extends Component {
  render() {
    const { photos } = this.props;
    return (
      <section>
        <ul
          style={{
            listStyle: `none`,
            padding: 0,
            paddingBottom: '10vh',
            width: '100%',
            display: `grid`,
            gridGap: `15px`,
            gridTemplateColumns: `repeat(2, [col] 1fr )`,
            gridTemplateRows: `repeat(3, [row] auto  )`,
            /*scrollSnapType: 'mandatory',*/
          }}
        >
          {photos.map((photo, i) => (
            <li
              key={i}
              style={{
                gridColumn: checkSize(photo) ? 'auto' : 'col / span 2',
                display: 'inline-block',
                position: 'relative',
                /*scrollSnapAlign: 'start',
                scrollMarginTop: '10px',*/
              }}
            >
              <Img
                fluid={photo.childImageSharp.fluid}
                style={{ height: '100%' }}
              />
              <ImageHidder />
            </li>
          ))}
        </ul>
        <a
          href="#top"
          style={{
            position: 'fixed',
            bottom: '0vh',
            left: '50%',
            zIndex: 1,
            color: 'white',
            fontSize: '2rem',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <FaArrowCircleUp />
        </a>
      </section>
    );
  }
}
export default Gallery;
function checkSize(photo) {
  //console.log(photo);
  return photo.childImageSharp.fluid.aspectRatio < 1;
}
